<template>
	<div class="">
		<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
		<div style="">
			<div class="printer_con">
				<div class="printer_tit">
					<div>
						Warehouse Value-added Services List
					</div>
				</div>
				<div v-for="(priterData,indexP) in tableData" :key="indexP" style="margin-bottom: 20px;">
					<div style="display: flex;justify-content: space-between; align-items: center; ">
						<!-- <barcode :code="priterData.workNo"></barcode> -->
						<div style="font-size: 12pt; text-align: left; word-break: break-all;white-space:normal; ">
							<div>WorkOrder#&nbsp;<strong>{{priterData.workNo}}</strong></div>
						</div>

						<div style="font-size: 12pt; text-align: right; word-break: break-all;white-space:normal; ">
							<div>Warehouse#&nbsp;{{priterData.whNo}}</div>
							<div>Date#&nbsp;{{priterData.commitDate}}</div>
						</div>
					</div>

					<table class="simTable printerTable">

						<tbody>
							<tr>
								<td colspan="5"><strong>Title#&nbsp;</strong>{{priterData.title}}</td>
							</tr>
							<tr>
								<td colspan="5"><strong>Remark#&nbsp;</strong>
									<div class='pre-text'><strong>{{priterData.remark}}</strong></div>
								</td>
							</tr>
						</tbody>

						<tbody class="text_center">
							<tr class="gray_bg">
								<th width="100">Module Name</th>
								<th width="100">Service Name</th>

								<th width="100">SKU</th>
								<th width="100">Operation QTY</th>
								<th width="100">Place</th>
							</tr>
						</tbody>
						<tbody v-if="priterData.addServiceList" class="text_center">
							<tr v-for="(item, index) in priterData.addServiceList" :key="index">
								<td>{{ item.bizTypeName }}</td>
								<td>{{ item.serviceTypeName }}</td>

								<td>{{item.goodsSku}}</td>
								<td>{{item.quantity}}</td>
								<td>{{ item.place }}</td>
							</tr>
						</tbody>
						<tbody class="text_center">
							<tr class="">
								<th colspan="3" style="text-align:right;">Total</th>
								<th>
									<span v-if="priterData.addServiceList">
										{{priterData.addServiceList.reduce((total, value) => parseInt(total) + parseInt(value.quantity?value.quantity:0), 0)}}
									</span>
								</th>
								<th></th>
							</tr>
						</tbody>

					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	// import Vue from 'vue';
	// import Print from '@/plugins/print.js';
	// Vue.use(Print); // 注册
	// import barcode from '@/components/Common/barcode.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-互易天下-增值服务清单' // set a title
		// 	// meta: [{ // set meta
		// 	//   name: '互易天下-买家中心-收货账单',
		// 	//   content: '互易天下-厂家共享平台-买家中心-收货账单'
		// 	// }],
		// 	// link: [{                 // set link
		// 	//   rel: 'asstes',
		// 	//   href: 'https://assets-cdn.github.com/'
		// 	// }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			tableData: {
				default: function() {
					return [];
				},
				type: Array
			}
		},
		components: {
			// barcode
		},
		data() {
			return {
				// dialogFile: false,
				// loadingUpload: false,
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,
				// filterData: {
				// 	ids: []
				// },
				// listUrl: [],
				// loading_load: false,
				// priterData: {},
				// tableData: [],
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				// if (this.$route.query && this.$route.query.packId) {
				//   this.filterData.packId = this.$route.query.packId;
				// }
				this.initData();
			}
		},
		//创建时
		created() {
			// if (this.isSel) {
			//   this.filterData.status = this.status;
			// }
			// if (this.$route.query && this.$route.query.ids) {
			// 	this.filterData.ids = this.$route.query.ids.split(',');
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				// this.getPageData();
			},
			//打印
			// printerAction() {
			//   window.print();
			//   // this.$print(this.$refs.print);
			// },
			//请求分页数据
			// getPageData() {
			// 	this.loading_load = true;

			// 	this.$http
			// 		.put(this.$urlConfig.WhPrinterValAddServiceQuery, {
			// 			ids: this.filterData.ids
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('列表，请求成功');
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				//表格显示数据
			// 				this.tableData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('列表，请求失败');
			// 			this.$message.error(this.$t('hytxs0000030'));
			// 			this.loading_load = false;
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
	@import url(../assets/css/printer.less);
</style>